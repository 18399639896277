import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import './App.less';
import LoginPage from './pages/login/Login';
import LayoutPage from './layout/layout';
import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';
// import RegisterPage from './pages/register/Register';

export default function App() {
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        // eslint-disable-next-line operator-linebreak
        e.message === 'ResizeObserver loop limit exceeded' ||
        e.message === 'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <Switch>
            <PublicRoute path="/login" component={LoginPage} />
            {/* <PublicRoute path="/register" component={RegisterPage} /> */}
            <PrivateRoute path="*" component={LayoutPage} />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}
