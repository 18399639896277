/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Ant Design
import { Form, Input, Button, Row, Col, Card, Spin, Layout, Image, Divider, Typography, Space, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/antd.less';
import axios from 'axios';
import './login.css';
// import logo from '../../assets/images/vta_logo.png';
import { ShieldLockFill } from 'react-bootstrap-icons';
import { setUserSession } from '../../utils/AuthService';

const { Title } = Typography;
const { Content, Footer } = Layout;
const { Item } = Form;
const api = process.env.REACT_APP_LOCAL_URL;
const staging = process.env.REACT_APP_NODE_ENV;

export default function Login() {
  const history = useHistory();

  const currentYear = new Date().getFullYear();

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (values) => {
    // setLoading(true);

    console.log(values);
    axios
      .post(`${api}/v1/auth/login`, {
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        // setLoading(false);
        setUserSession(values.remember, response.data.tokens, response.data.user);
        history.push('/');
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        message.error(e.message);
      });
  };

  return (
    <>
      <Layout className="login-layout" style={{ minHeight: '100vh' }}>
        <Row type="flex" align="middle" className="login-wrapper">
          <Card className="login-card">
            <Title level={3} className="login-form__header">Log In</Title>
            <Space direction="vertical" />
            <Form
              layout="vertical"
              className="login-form"
              name="normal_login"
              initialValues={{
                remember: false,
              }}
              onFinish={(val) => handleSubmit(val)}>
              <Form.Item label="Email" name="email">
                <Input className="login-form__input" placeholder="Enter email" />
              </Form.Item>
              <Form.Item label="Password" name="password">
                <Input.Password visibilityToggle={false} className="login-form__input" placeholder="Enter password" />
              </Form.Item>

              <Form.Item>
                <Row type="flex" align="middle" justify="space-between">
                  <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 16 }} noStyle>
                    <Checkbox className="login-form__remember-me">Remember me</Checkbox>
                  </Form.Item>

                  <a className="login-form__forgot" target="_blank" rel="noreferrer" href="/">
                    Forgot password
                  </a>
                </Row>
              </Form.Item>

              <Form.Item>
                <Button block type="primary" htmlType="submit" className="login-form__button">
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <div className="login-secure"><ShieldLockFill className="login-form__icon" width={24} height={24} />Never share any of your login details with anyone</div>
        </Row>
      </Layout>
    </>
  );
}
