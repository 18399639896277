/* eslint-disable max-len */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DashboardPage from '../pages/dashboard/Dashboard';
import PlansPage from '../pages/pricingPlan/pricingPlan';
import FormNewPrice from '../pages/pricingPlan/NewPricingPlan';
import EditPricePlan from '../pages/pricingPlan/EditPricingPlan';
import UserManagement from '../pages/userManagement/userManagement';
import UserTransactions from '../pages/userTransactions/userTransactions';
import AICredit from '../pages/aiCredit/aiCredit';
import NotFoundPage from './404';

export default function RouteList() {
  const handleAdminRoutes = () => (
    <>
      <Route exact path="/" component={DashboardPage} />
      <Route key="/index" exact path="/dashboard" component={DashboardPage} />
      <Route key="/pricing-plans" exact path="/pricing-plans" component={PlansPage} />
      <Route
        key="/pricing-plans/add-new"
        exact
        path="/pricing-plans/add-new"
        component={FormNewPrice}
      />
      <Route
        key="/pricing-plans/edit"
        exact
        path="/pricing-plans/edit"
        component={EditPricePlan}
      />
      <Route key="/user-management" exact path="/user-management" component={UserManagement} />
      <Route key="/user-transaction" exact path="/user-transaction" component={UserTransactions} />
      <Route key="/ai-credit" exact path="/ai-credit" component={AICredit} />
    </>
  );

  // TODO modify when api implement
  const handleRolesRoutes = (roles) => {
    switch (roles) {
      case 'operator':
        return NotFoundPage();
      case 'admin':
        return handleAdminRoutes();
      default:
        return NotFoundPage();
    }
  };

  return <Switch>{handleRolesRoutes('admin')}</Switch>;
}
