/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Divider, message, Typography, DatePicker, Form } from 'antd';

import axios from 'axios';

import '../../pages/dashboard/Dashboard.css';
import { getToken } from '../../utils/AuthService';

const token = getToken();
const api = process.env.REACT_APP_LOCAL_URL;

export default function DashboardTopMostStatistics() {
  const [loadingDashboardTopStatistics, setLoadingDashboardTopStatistics] = useState(false);
  const [userData, setUserData] = useState([]);
  const [totalActivePlan, setTotalActivePlan] = useState([]);
  const [userSubsData, setUserSubsData] = useState([]);
  const [userTransactionData, setUserTransactionData] = useState([]);
  const [apiUsageData, setApiUsageData] = useState([]);
  const [totalUnsubs, setTotalUnsubs] = useState();
  const [startDates, setStartDates] = useState();
  const [endDates, setEndDates] = useState();

  const { Item } = Form;
  const [searchForm] = Form.useForm();

  useEffect(() => {
    fetchUsers();
    fetchUsersData();
    fetchUserSubs();
    fetchUserTransaction();
    fetchPlans();
    fetchApiUsages();
  }, [startDates, endDates]);

  const handleStartDate = (date) => {
    if (date !== null) {
      console.log(date.toISOString());
      setStartDates(date.toISOString());
    }
  };

  const handleEndDate = (date) => {
    if (date !== null) {
      console.log(date.toISOString());
      setEndDates(date.toISOString());
    }
  };

  const SearchBar = () => (
    <Form layout="horizontal" form={searchForm}>
      <Row
        gutter={16}
        justify="end"// Align the row to the right
      >
        <Col span={4}>  {/* Adjust the span as needed */}
          <Item name={['statisticSearch', 'startDate']}>
            <DatePicker onChange={handleStartDate} style={{ width: '100%' }} allowClear />
          </Item>
        </Col>
        <Col span={4}>  {/* Adjust the span as needed */}
          <Item name={['statisticSearch', 'endDate']}>
            <DatePicker onChange={handleEndDate} style={{ width: '100%' }} allowClear />
          </Item>
        </Col>
      </Row>
    </Form>
  );

  const fetchUsers = async () => {
    await axios.get(`${api}/v1/users`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        startCreatedDate: startDates,
        endCreatedDate: endDates,
        planTimeout: '-1',
      },
    })
      .then((results) => {
        setTotalUnsubs(results.data.totalResults);
      })
      .catch((error) => {
        message.error(`Failed to retrive Plan data. ${error}`);
      });
  };

  const fetchUsersData = async () => {
    await axios.get(`${api}/v1/users`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        startCreatedDate: startDates,
        endCreatedDate: endDates,
      },
    })
      .then((results) => {
        const usersData = results.data.totalResults;
        setUserData(usersData);
      })
      .catch((error) => {
        message.error(`Failed to retrive Plan data. ${error}`);
      });
  };

  const fetchUserSubs = async () => {
    await axios.get(`${api}/v1/users`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        startCreatedDate: startDates,
        endCreatedDate: endDates,
        planTimeout: '12',
      },
    })
      .then((results) => {
        const plans = results.data.users;
        console.log(plans.length);
        // // Calculate the total count of active plans with status 'active'
        // const totalActiveStatus = plans.filter((plan) => plan.status === 'active').length;
        // console.log('Total active plans:', totalActiveStatus);

        // Set the userSubsData state with the status data
        setUserSubsData(plans.length);
      })
      .catch((error) => {
        message.error(`Failed to retrive Plan data. ${error}`);
      });
  };

  const fetchPlans = async () => {
    await axios.get(`${api}/v1/plans`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        startDate: startDates,
        endDate: endDates,
      },
    })
      .then((response) => {
        const plans = response.data.pricePlan;
        // Calculate the total count of active plans using filter and length
        const totalActivePlans = plans.filter((plan) => plan.isActive).length;

        // Set the total count into state
        setTotalActivePlan(totalActivePlans);
      })
      .catch((error) => {
        message.error(`Failed to retrieve Plan data. ${error}`);
      });
  };

  const fetchUserTransaction = async () => {
    await axios.get(`${api}/v1/usertransactions`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        startDate: startDates,
        endDate: endDates,
        status: 'success',
      },
    })
      .then((results) => {
        const transaction = results.data.totalResults;
        // Calculate the total count of active plans using filter and length
        // const totalPurchasedPlan = transaction.length;
        setUserTransactionData(transaction);
      })
      .catch((error) => {
        message.error(`Failed to retrive Plan data. ${error}`);
      });
  };

  const fetchApiUsages = async () => {
    await axios.get(`${api}/v1/apiusages`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        startDate: startDates,
        endDate: endDates,
      },
    })
      .then((results) => {
        const apiUsage = results.data.totalResults;
        console.log(apiUsage);
        setApiUsageData(apiUsage);
      })
      .catch((error) => {
        message.error(`Failed to retrive Api Usage data. ${error}`);
      });
  };

  return (
    <div>
      <div>
        <SearchBar />
      </div>
      <div className="site-card-wrapper">
        <Row gutter={[24, 16]}>
          <Col span={8}>
            <Card bordered={false} className="dashboard-statistics-card">
              <Row className="dashboard-statistics-card-title">Total Active Pricing Plans</Row>
              <h1>{totalActivePlan}</h1>
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false} className="dashboard-statistics-card">
              <Row className="dashboard-statistics-card-title">Total Pricing Plans Purchased</Row>
              <h1>{userTransactionData}</h1>
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false} className="dashboard-statistics-card">
              <Row className="dashboard-statistics-card-title">Total API Usage</Row>
              <h1>{apiUsageData}</h1>
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false} className="dashboard-statistics-card">
              <Row className="dashboard-statistics-card-title">Total Subscriber</Row>
              <h1>{userData}</h1>
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false} className="dashboard-statistics-card">
              <Row className="dashboard-statistics-card-title">Total New Subscriber</Row>
              <h1 className="dashboard-new-subscribers-text">{userSubsData ? `+${userSubsData}` : `${userSubsData}`}</h1>
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={false} className="dashboard-statistics-card">
              <Row className="dashboard-statistics-card-title">Total Unsubscriber</Row>
              <h1 className="dashboard-unsubscribers-text">{totalUnsubs ? `-${totalUnsubs}` : `${totalUnsubs}`}</h1>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
