/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  Breadcrumb,
  PageHeader,
  Table,
  Layout,
  Input,
  Row,
  Col,
  Button,
  Modal,
  Form,
  DatePicker,
  message,
  Tooltip,
  Skeleton,
  Select,
  Switch,
  Space,
} from 'antd';
import { PlusOutlined, UploadOutlined, EyeOutlined, DeleteOutlined, CheckOutlined, InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { utils, writeFile } from 'xlsx';

import { getToken, getUser } from '../../utils/AuthService';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;
const api = process.env.REACT_APP_LOCAL_URL;

export default function Quotation() {
  const token = getToken();
  const user = getUser();

  const [searchForm] = Form.useForm();

  // useState Declaration
  const [isLoading, setIsLoading] = useState(false);
  const [searchPlan, setSearchPlan] = useState('');
  const [data, setData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [planListData, setPlanListData] = useState();
  const [startDates, setStartDates] = useState();
  const [endDates, setEndDates] = useState();
  const [planTitle, setPlanTitle] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '50'],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
  });

  useEffect(() => {
    setIsLoading(true);
    fetchPlanData();
    setIsLoading(false);
    if (isLoading === false) {
      rerenderList(planData);
    }
  }, [planTitle, startDates, endDates, isLoading]);

  // const fetchPlanData = async () => {
  //   await axios
  //     .get(`${api}/v1/plans`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token.access.token}`,
  //       },
  //       params: {
  //         sortBy: 'createdDate',
  //         // sortOrder: 'desc',
  //         title: planTitle,
  //         startDate: startDates,
  //         endDate: endDates,
  //       },
  //     })
  //     .then((results) => {
  //       console.log('In here');
  //       console.log(results.data.pricePlan);
  //       setPlanData(results.data.pricePlan);
  //       return results;
  //     })
  //     .catch((error) => {
  //       message.error(`Failed to retrive Plan data. ${error}`);
  //     });
  // };

  useEffect(() => {
    fetchPlanData();
  }, []);

  const fetchPlanData = async () => {
    try {
      const response = await axios.get(`${api}/v1/plans`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
        params: {
          sortBy: 'createdDate',
          // sortOrder: 'desc',
          title: planTitle,
          startDate: startDates,
          endDate: endDates,
        },
      });

      // Assuming response.data.pricePlan is an array of plan objects
      const sortedPlanData = response.data.pricePlan.sort((a, b) => {
        // Assuming createdDate is in ISO 8601 format
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);
        return dateA - dateB; // Change to dateB - dateA for descending order
      });

      setPlanData(sortedPlanData);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalResults,
        },
      });
    } catch (error) {
      message.error(`Failed to retrieve Plan data. ${error}`);
    }
  };

  const rerenderList = (plan) => {
    const list = [];
    for (let i = 0; i < plan.length; i++) {
      list.push({
        key: plan[i]._id,
        priceplanid: plan[i].priceplanid,
        title: plan[i].title,
        price: plan[i].price,
        count: plan[i].count,
        createdDate: plan[i].createdDate,
        modifyDate: plan[i].modifyDate,
      });
    }
    setPlanListData(list);
  };

  const exportToCSV = async () => {
    // Make the request to the `exportToCSV` endpoint
    const response = await axios.get(`${api}/v1/plans/export`, {
      params: {
        page: tableParams.pagination?.current,
        limit: tableParams.pagination?.pageSize,
        sortBy: 'createdAt',
      },
    });

    // Save the CSV file to the client's computer
    const filename = 'plans.csv';
    const blob = new Blob([response.data], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
    downloadLink.setAttribute('download', filename);
    downloadLink.click();
  };

  const handleExport = useCallback(() => {
    let selectedRows = [];

    selectedRows = planListData.map(({ key, ...rest }) => rest);

    const renamedColumns = {
      priceplanid: 'Plan Id',
      title: 'Plan Name',
      price: 'Plan Price',
      count: 'Total User',
      createdDate: 'Created Date',
      modifyDate: 'Modified Date',
    };

    const transformedRows = selectedRows.map((row) =>
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (key === 'createdDate') {
          const createdDate = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: createdDate.toLocaleString('en-US', options) };
        }
        if (key === 'modifyDate') {
          const modifyDate = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: modifyDate.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {}));

    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');

    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');

    // Use writeFile instead of writeFileXLSX
    writeFile(wb, `PlanManagement_${formattedDate}.xlsx`);
  }, [planListData]);

  const showModal = (item) => {
    item.isModalVisible = true;
    setData([...data]);
  };

  const showUncheckModal = (item) => {
    item.isUncheckModalVisible = true;
    setData([...data]);
  };

  const handleOk = async (item) => {
    setIsLoading(true);
    item.isModalVisible = false;
    item.isChecked = item.switchTempState; // Update switch state with temp state

    const currentDate = new Date();
    const isoDate = currentDate.toISOString();

    const id = item._id;
    console.log(id);
    setData([...data]);
    await axios.patch(`${api}/v1/plans/${id}`,
      {
        isActive: true,
        modifyDate: isoDate,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      }).then((res) => {
      // Handle the response from the API here
      console.log('Patch successful:', res.data.pricePlan);
      setIsLoading(false);
      // window.location.reload();
      // Perform any additional actions based on the response
      // For example, show a success message, update UI, etc.
    }).catch((error) => {
      // Handle any errors that occur during the API request
      console.error('Patch error:', error);
      // Perform any error handling actions
      // For example, show an error message, revert UI changes, etc.
    });
  };

  const handleUncheckOk = async (item) => {
    setIsLoading(true);
    item.isUncheckModalVisible = false;
    item.isChecked = item.switchTempState; // Update switch state with temp state

    const currentDate = new Date();
    const isoDate = currentDate.toISOString();

    const id = item._id;
    console.log(id); // undefined
    setData([...data]);
    await axios.patch(`${api}/v1/plans/${id}`,
      {
        isActive: false,
        modifyDate: isoDate,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      }).then((res) => {
      // Handle the response from the API here
      console.log('Patch successful:', res.data.pricePlan);
      setIsLoading(false);
      // window.location.reload();
      // Perform any additional actions based on the response
      // For example, show a success message, update UI, etc.
    }).catch((error) => {
      // Handle any errors that occur during the API request
      console.error('Patch error:', error);
      // Perform any error handling actions
      // For example, show an error message, revert UI changes, etc.
    });
    setIsLoading(false);
  };

  const handleCancel = (item) => {
    item.isModalVisible = false;
    item.isUncheckModalVisible = false;
    setData([...data]);
  };

  const handleSwitchChange = useCallback((checked, item) => {
    console.log('Item:', item);
    console.log('Switch value:', checked);
    item.switchTempState = checked; // Store temporary switch state
    if (!checked) {
      showUncheckModal(item);
    } else {
      showModal(item);
    }
  }, [showUncheckModal, showModal]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination: {
        ...tableParams.pagination,
        ...pagination,
      },
      filters: {
        ...tableParams.filters,
        ...filters,
      },
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const submitForm = async () => {
    const values = await searchForm.validateFields();
    setPlanTitle(values.planManagement.title);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'priceplanid',
      key: 'planID',
    },
    {
      title: 'Plan Name',
      dataIndex: 'title',
      key: 'planName',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'planPrice',
      align: 'center',
    },
    {
      title: 'Subscription Count',
      dataIndex: 'count',
      key: 'pricePlanID',
      align: 'center',
    },
    {
      title: 'Activation',
      dataIndex: 'isActive',
      key: 'activition',
      align: 'center',
      render: (_, item) => (
        <div>
          <Switch
            checked={item.isActive}
            onChange={(checked) => handleSwitchChange(checked, item)}
          />
          <Modal
            title="Confirmation"
            open={item.isModalVisible}
            onOk={() => handleOk(item)}
            onCancel={() => handleCancel(item)}
            footer={[
              <Button key="cancel" onClick={() => handleCancel(item)}>Cancel</Button>,
              <Button key="deactivate" type="primary" onClick={() => handleOk(item)}>
                <CheckOutlined /> Yes, Activate
              </Button>,
            ]}
          >
            <div style={{ textAlign: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '32px' }} />
              <h2>Are you sure you want to Activate?</h2>
              <p>If you confirm this action, you are still able to deactivate the plan later.</p>
            </div>
          </Modal>
          <Modal
            open={item.isUncheckModalVisible}
            onOk={() => handleUncheckOk(item)}
            onCancel={() => handleCancel(item)}
            footer={[
              <Button key="cancel" onClick={() => handleCancel(item)}>Cancel</Button>,
              <Button key="deactivate" type="primary" danger onClick={() => handleUncheckOk(item)}>
                <DeleteOutlined /> Yes, Deactivate
              </Button>,
            ]}
          >
            <div style={{ textAlign: 'center' }}>
              <InfoCircleOutlined style={{ fontSize: '32px' }} />
              <h1>Are you sure you want to Deactivate?</h1>
              <p>If you confirm this action, you are unable to undo.</p>
            </div>
          </Modal>
          {item.isChecked ? console.log('Yes') : console.log('No')}
        </div>
      ),
    },
    {
      title: 'Publish Date',
      dataIndex: 'createdDate',
      key: 'publishDate',
      align: 'center',
      render: (createdDate) => new Date(createdDate).toLocaleString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
    },
    {
      title: 'Last Modified Date',
      dataIndex: 'modifyDate',
      key: 'modifyDate',
      align: 'center',
      render: (modifyDate) => new Date(modifyDate).toLocaleString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
    },
    {
      title: 'Action',
      dataIndex: '_id',
      key: 'action',
      align: 'center',
      fixed: 'right',
      render: (id, item) => (
        <Space>
          <Link
            to={{
              pathname: 'pricing-plans/edit',
              state: { planId: id },
            }}
          >
            <EditOutlined style={{ color: 'blue', fontSize: '18px' }} />
          </Link>
        </Space>
      ),
    },
  ];

  // function to handle searching for plan title
  const handleSearch = () => {
    // Perform the search logic here
    console.log('Searching for:', searchPlan);
    submitForm();
    // ...
  };

  // keyPress function for pressing any key on keyboard
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      handleSearch();
    }
  };

  // Table component
  const TablePlanManagement = () => (
    <div className="table-content">
      {isLoading ? (
        <Skeleton />
      ) : (
        <Table
          scroll={{ x: 1500 }}
          columns={columns}
          dataSource={planData}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
          // pagination={{
          //   total: planData.length,
          //   showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          //   defaultCurrent: 1,
          //   showSizeChanger: true,
          //   defaultPageSize: 10,
          //   pageSizeOptions: ['10', '50'],
          // }}
          style={{ marginInline: '24px' }}
        />
      )}
    </div>
  );

  // onChange function for date filtering
  const handleDateChange = (value) => {
    if (value && value.length === 2) {
      // Assuming that the selected date range is an array with two elements: [startDate, endDate]
      const [startDate, endDate] = value;
      setStartDates(startDate.toISOString());
      setEndDates(endDate.toISOString());
      console.log('Selected date range:', startDate.toISOString(), endDate.toISOString());
    }
  };

  // Search bar for filtering data
  const SearchBar = () => (
    <Form layout="horizontal" form={searchForm}>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 20,
        }}>
        <Col span={8}>
          <div className="header-section-search-bar-section">Plan Name</div>
          <Item name={['planManagement', 'title']} initialValue={searchPlan}>
            <Input initialValue={searchPlan} placeholder="Enter Plan Name" onKeyDown={handleKeyPress} allowClear />
          </Item>
        </Col>
        <Col span={8}>
          <div className="header-section-search-bar-section">
            Published Date
          </div>
          <Item name={['planManagement', 'publishDate']}>
            <DatePicker.RangePicker onChange={handleDateChange} style={{ width: '100%' }} />
          </Item>
        </Col>
      </Row>
    </Form>
  );

  // Export button to export data into csv file
  const ExportButton = () => (
    <Button
      className="filter-btn"
      icon={<UploadOutlined />}
      size="large"
      onClick={exportToCSV} 
      >
      Export
    </Button>
  );

  // Button to create new plan at NewPlanPage
  const CreateNewPlanButton = () => (
    <Link to="/pricing-plans/add-new">
      <Button
        className="filter-btn"
        icon={<PlusOutlined />}
        size="large"
        type="primary"
      >
        Create Pricing Plan
      </Button>
    </Link>
  );

  // Breadcrumb navigation
  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      {/* <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/quotation-history">Pricing Plans</a>
        </Breadcrumb.Item>
      </Breadcrumb> */}
      <Row style={{ justifyContent: 'space-between' }}>
        <PageHeader title="Pricing Plans" className="basic-pageheader" />
        <Col style={{ display: 'flex', alignItems: 'center', marginRight: '20px', gap: '5px' }}>
          <ExportButton />
          <CreateNewPlanButton />
        </Col>
      </Row>
    </div>
  );

  // Main component
  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <div style={{ padding: 20 }}>
          <SearchBar />
        </div>
        <TablePlanManagement />
      </Content>
    </>
  );
}
