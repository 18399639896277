/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useLocation, Link, useParams, useHistory } from 'react-router-dom';

import {
  PageHeader,
  Breadcrumb,
  Form,
  InputNumber,
  Row,
  Button,
  Input,
  Col,
  Tooltip,
  Select,
  message,
  DatePicker,
  Layout,
  Card,
  Dropdown, 
  Space, 
  Typography,
  Modal,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined, InfoCircleOutlined, SendOutlined, DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';

import { getToken } from '../../utils/AuthService';

import '../../styles/general.css';

const { Option } = Select;
const { Content } = Layout;
const { Item } = Form;
const { TextArea } = Input;
const api = process.env.REACT_APP_LOCAL_URL;
const staging = process.env.REACT_APP_NODE_ENV;

export default function EditQuotationForm() {
  const token = getToken();
  // const location = useLocation();
  // const { planId } = location.state;
  const [form] = Form.useForm();
  const history = useHistory();

  // const [loadingPSL, setLoadingPSL] = useState(false);
  // const [planData, setPlanData] = useState([]);
  // const [monthlyRate, setMonthlyRate] = useState('');
  const [titlePlan, setTitlePlan] = useState('');
  const [planData, setPlanData] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const { planId } = useParams();
  const location = useLocation();
  const item = location.state?.planId || ''; // Get the planId from URL parameters

  const planType = [
    {
      value: 'Daily',
      label: 'Daily',
    },
    // {
    //   value: 'Weekly',
    //   label: 'Weekly',
    // },
    // {
    //   value: 'Monthly',
    //   label: 'Monthly',
    // },
    {
      value: 'Yearly',
      label: 'Yearly',
    },
  ];

  useEffect(() => {
    console.log(item);
    const fetchPlanData = async () => {
      console.log('In Here');
      try {
        const response = await axios.get(`${api}/v1/plans/${item}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            sortBy: 'desc',
          },
        });
        console.log(response.data);
        const receivedPlanData = response.data; // Assuming you expect only one plan
        const { priceplanid } = receivedPlanData;
        const idWithoutPrefix = priceplanid.startsWith('ID') ? priceplanid.substring(2) : priceplanid;
        if (receivedPlanData) {
          setInitialValues({
            plan: {
              title: receivedPlanData.title,
              id: idWithoutPrefix,
              type: receivedPlanData.type,
              gracePeriod: receivedPlanData.gracePeriod,
              price: receivedPlanData.price,
              remarks: receivedPlanData.remarks,
            },
            plans: {
              created: moment(receivedPlanData.createdDate),
            },
          });
        }
        setPlanData(receivedPlanData);
      } catch (error) {
        message.error(`Failed to retrieve Plan data. ${error}`);
      }
    };

    fetchPlanData();
  }, [planId]);

  const validateMessages = {
    required: '${label} is required!',
    types: {
      number: '${label} is not a valid number!',
      string: '${label} is not a valid string!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinishFailed = (errorInfo) => {
    message.error('Failed to create new plan.', errorInfo);
  };

  const onPublish = async () => {
    try {
      const values = await form.validateFields();
      const currentDate = new Date();
      const isoDate = currentDate.toISOString();
      console.log('Selected Date:', isoDate);
      // Rest of your code for sending the data to the backend
      axios
        .patch(
          `${api}/v1/plans/${item}`,
          {
            title: values.plan.title,
            priceplanid: `ID${values.plan.id}`,
            type: values.plan.type,
            gracePeriod: values.plan.gracePeriod,
            price: values.plan.price,
            remarks: values.plan.remarks,
            modifyDate: isoDate,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
          },
        )
        .then((res) => {
          // console.log('Patch successful:', res.data);
          message.success('Plans updated successfully!');
          history.push('/pricing-plans');
          return true;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            const errorMessage = error.response.data.message;
            console.log(error.response.data);
            message.error(`${errorMessage}`);
          } else {
            console.error(error);
            message.error('An unexpected error occurred.');
          }
        });
      // redirect to pricing plan
    } catch (err) {
      console.log('err', err);
    }
  };

  const displayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <div style={{ marginLeft: 70 }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/pricing-plans">Pricing Plans</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit Plan</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row style={{ marginInline: '45px', justifyContent: 'space-between' }}>
        <PageHeader title="Edit Pricing Plan" className="basic-pageheader" />
        <Col style={{ display: 'flex', alignItems: 'center', gap: '5px', marginRight: '20px' }}>
          <DeleteButton />
          <PublishButton />
        </Col>
      </Row>
    </div>
  );

  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  const CreateNewQuotationForm = () => (
    <Card style={{ marginInline: '20px' }}>
      <Form
        form={form}
        layout="vertical"
        name="edit-work-info"
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
        initialValues={initialValues} 
        >
        <Col style={{ marginBottom: '10px' }}>
          <h1 style={{ fontSize: '24px' }}>Plan Details</h1>
          <Form.Item
            label="Name"
            name={['plan', 'title']}
            rules={[
              {
                required: true,
              },
            ]}>
            <Input placeholder="Enter Plan Name" />
          </Form.Item>
        </Col>

        <Col style={{ marginBottom: '10px' }}>
          <Form.Item
            label="ID"
            name={['plan', 'id']}
            rules={[
              {
                required: true,
              },
            ]}>
            <InputNumber prefix="ID" min={1} placeholder="Enter Plan ID" style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col style={{ marginBottom: '30px' }}>
          <h1 style={{ fontSize: '24px' }}>Features</h1>
          <Form.Item
            label="Type"
            name={['plan', 'type']}
            rules={[
              {
                required: true,
              },
            ]}>
            <Select
              defaultValue="Plan Type"
              onChange={handleChange}
              style={{ width: '100%' }}
              options={planType}
            />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            label="Grace Period"
            name={['plan', 'gracePeriod']}
            rules={[
              {
                required: true,
                type: 'number',
              },
            ]}>
            <InputNumber
              min={0}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            label="Price"
            name={['plan', 'price']}
            rules={[
              {
                required: true,
                type: 'number',
              },
            ]}>
            <InputNumber
              prefix="$"
              min={0}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row">
          <Form.Item
            label="Date"
            name={['plans', 'created']}
            rules={[
              {
                required: true,
              },
            ]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col className="gutter-row">
          <Form.Item
            label="Remarks"
            name={['plan', 'remarks']}
            rules={[
              {
                required: true,
              },
            ]}>
            <TextArea
              placeholder="Enter Text Here"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
        </Col>
      </Form>
    </Card>
  );

  const DeleteButton = () => {
    const id = item;
    const handleClick = async () => {
      setIsDeleteConfirmationModalOpen(true);
    };

    return (
      <Button
        className="filter-btn"
        icon={<DeleteOutlined />}
        size="large"
        onClick={handleClick}
        >
        Delete
      </Button>
    );
  };

  const PublishButton = () => (
    <Button
      className="filter-btn"
      icon={<SendOutlined />}
      size="large"
      type="primary"
      onClick={onPublish}
    >
      Publish
    </Button>
  );

  const onDeletePricingPlan = async () => {
    try {
      await axios.delete(`${api}/v1/plans/${item}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      history.push('/pricing-plans'); 
      message.success(`${planData.title} Successfully Deleted`);
    } catch (error) {
      console.error(error);
      message.error(`${error}`);
    }
  };

  const DeleteConfirmationModal = () => (
    <Modal
      title={`Delete Pricing Plan: ${planData.title}`}
      open={isDeleteConfirmationModalOpen}
      onOk={onDeletePricingPlan}
      onCancel={() => setIsDeleteConfirmationModalOpen(false)}
    >
      <p>Are you sure you want to delete {planData.title}?</p>
      <p>This action cannot be undone.</p>
    </Modal>
  );  

  return (
    <>
      {displayBreadCrumb()}
      <Content className="content-area">
        <div style={{ margin: '50px' }}>
          <CreateNewQuotationForm />
        </div>
        <DeleteConfirmationModal />
      </Content>
    </>
  );
}
