/* eslint-disable no-console */
/* eslint-disable padded-blocks */
/* eslint-disable quotes */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Form, Input, Select, DatePicker, PageHeader, Row, Layout, Col, Skeleton, Tag, Table } from 'antd';
import axios from 'axios';
import moment from 'moment';
import './AuditLog.css';
import { getToken } from '../../utils/AuthService';

import AiCreditSettingButton from './aiCreditSettingButton';
import AiCreditDeductSettingButton from './aiCreditDeductRatioSettingButton';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Item } = Form;
const api = process.env.REACT_APP_LOCAL_URL;

export default function AiCreditPage() {
  const token = getToken();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [aiCredit, setAiCredit] = useState(null);
  const [creditData, setCreditData] = useState([]);
  const [filterParams, setFilterParams] = useState({
    transactionId: null,
    userName: null,
    amount: null,
    status: null,
    startDate: null,
    endDate: null,
    page: null,
    limit: null,
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10', '15', '20', '25'],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
  });

  useEffect(() => {
    const getLatestSettingData = async () => {
      try {
        const response = await axios.get(`${api}/v1/credits/setting`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        setAiCredit(response.data.settingData[0].aiCreditValue);
      } catch (error) {
        console.error(error);
      }
    };

    getLatestSettingData();
  }, []);

  useEffect(() => {
    fetchCreditTransactions();
  }, [filterParams.status, filterParams.endDate]);

  useEffect(() => {
    fetchCreditTransactions();
  }, [JSON.stringify(tableParams)]);

  async function fetchCreditTransactions() {
    try {
      const params = { ...filterParams };

      // proceed to make api call without filter if status = all
      if (params.status === 'all') {
        form.resetFields();
        delete params.status;
      }

      // remove from params(...filterParams) if empty string(no value) 
      Object.keys(params).forEach((key) => {
        const filterParam = params[key];
        if (filterParam || (filterParam && filterParam.trim().length > 0)) {
          // Include the parameter only if it has a value
          params[key] = filterParam;
        } else {
          // Remove the parameter if it's empty, null, or undefined
          delete params[key];
        }
      });

      params.page = tableParams.pagination.current;
      params.limit = tableParams.pagination.pageSize;

      const response = await axios.get(`${api}/v1/credits/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      setCreditData(response.data.creditTransactions);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalResults,
        },
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleStatusChange = (value) => {
    setFilterParams((prevFilterParams) => ({
      ...prevFilterParams,
      status: value,
      ...(value === 'all' && {
        transactionId: null,
        userName: null,
        amount: null,
        startDate: null,
        endDate: null,
      }),
    }));
  };

  const handleDateChange = (dates) => {
    if (dates) {
      setFilterParams((prevFilterParams) => ({
        ...prevFilterParams,
        startDate: dates[0] ? dates[0].toISOString() : null,
        endDate: dates[1] ? dates[1].toISOString() : null,
      }));
    } else {
      setFilterParams((prevFilterParams) => ({
        ...prevFilterParams,
        startDate: null,
        endDate: null,
      }));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      searchData();
    }
  };

  const searchData = async () => {
    try {
      await form.validateFields();
      fetchCreditTransactions();
    } catch (error) {
      console.error('Validation error:', error);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevTableParams) => ({
      pagination: {
        ...prevTableParams.pagination,
        ...pagination,
      },
      filters: {
        ...prevTableParams.filters,
        ...filters,
      },
      sorter,
    }));
  };

  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionID',
      align: 'center',
    },
    {
      title: 'Username',
      dataIndex: 'userDetails',
      key: 'username',
      align: 'center',
      render: (data) => {
        if (data) {
          return (
            <p>{data.userName}</p>
          );
        }
        return '-';
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
    {
      title: 'AI Credit',
      dataIndex: 'aiCredit',
      key: 'aiCredit',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (statuses) => {
        const colorMap = {
          success: 'green',
          pending: 'blue',
          failed: 'red',
          cancelled: 'grey',
        };

        const color = colorMap[statuses] || 'defaultColor';

        return (
          <Tag style={{ fontWeight: 500 }} color={color} key={statuses}>
            {statuses.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      align: 'center',
      render: (data) => (data ? moment(data).format('DD-MM-YYYY h:mm A') : '-'),
    },
  ];

  const AuditLogTable = () => (
    <div className="table-content">
      {isLoading ? (
        <Skeleton />
      ) : (
        <Table
          scroll={{ y: 1500 }}
          columns={columns}
          dataSource={creditData}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
          style={{ marginInline: '24px' }}
        />
      )}
    </div>
  );

  return (
    <>
      <div className="breadcrumb-layout">
        <Row style={{ justifyContent: 'space-between' }}>
          <PageHeader title="AI Credit" className="basic-pageheader" />
          <Col style={{ display: 'flex', alignItems: 'center', marginRight: '3rem', gap: '5px' }}>
            <AiCreditSettingButton />
            <AiCreditDeductSettingButton aiCredit={aiCredit} />
          </Col>
        </Row>
      </div>
 
      <Content className="layout-background">
        <div style={{ padding: 20 }}>
          <Form layout="horizontal" form={form}>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 20,
              }}
            >
              <Col span={24}>
                <Row gutter={8}>
                  <Col span={5}>
                    <div className="header-section-search-bar-section">Transaction Id</div>
                    <Item name={['filter', 'transactionId']}>
                      <Input
                        initialvalues={filterParams.transactionId}
                        placeholder="Enter Transaction Id"
                        onKeyDown={handleKeyDown}
                        allowClear
                        onChange={(e) => setFilterParams({ ...filterParams, transactionId: e.target.value })}
                      />
                    </Item>
                  </Col>
                  <Col span={5}>
                    <div className="header-section-search-bar-section">Username</div>
                    <Item name={['filter', 'userName']}>
                      <Input
                        initialvalues={filterParams.userName}
                        placeholder="Enter Username"
                        onKeyDown={handleKeyDown}
                        allowClear
                        onChange={(e) => setFilterParams({ ...filterParams, userName: e.target.value })}
                      />
                    </Item>
                  </Col>
                  <Col span={5}>
                    <div className="header-section-search-bar-section">Amount</div>
                    <Item name={['filter', 'amount']}>
                      <Input
                        initialvalues={filterParams.amount}
                        placeholder="Enter Amount"
                        onKeyDown={handleKeyDown}
                        allowClear
                        onChange={(e) => setFilterParams({ ...filterParams, amount: e.target.value })}
                      />
                    </Item>
                  </Col>
                  <Col span={4}>
                    <Item name={['filter', 'status']}>
                      <div className="header-section-search-bar-section">
                        Status
                        <Select
                          allowClear
                          showSearch
                          style={{ width: '100%' }}
                          value={filterParams.status}
                          onChange={handleStatusChange}
                          options={[
                            {
                              value: 'all',
                              label: 'All',
                            },
                            {
                              value: 'success',
                              label: 'Success',
                            },
                            {
                              value: 'failed',
                              label: 'Failed',
                            },
                            {
                              value: 'pending',
                              label: 'Pending',
                            },
                            {
                              value: 'cancelled',
                              label: 'Cancelled',
                            },
                          ]}
                        />
                      </div>
                    </Item>
                  </Col>
                  <Col span={5}>
                    <div>Created Date</div>
                    <Item name={['filter', 'createdDate']}>
                      <RangePicker style={{ width: '100%' }} onChange={handleDateChange} allowClear />
                    </Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
        <AuditLogTable />
      </Content>
    </>
  );
}
