/* eslint-disable indent */
/* eslint-disable no-tabs */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Form, Input, Select, DatePicker, Button, PageHeader, Row, Layout, Col, Skeleton, Tag, Table, Modal } from 'antd';
import { getToken } from '../../utils/AuthService';

const api = process.env.REACT_APP_LOCAL_URL;

export default function AiCreditDeductSettingButton({ aiCredit }) {
  const token = getToken();

  const [amountToCharge, setAmountToCharge] = useState('');
  const [isSettingPopUpVisible, setIsSettingPopUpVisible] = useState(false);
  const [aiCreditError, setAiCreditError] = useState('');

  const handleSettingButton = () => {
    setIsSettingPopUpVisible(true);
  };

  const getLatestSettingData = async () => {
    try {
      const response = await axios.get(`${api}/v1/credits/deductAiCreditSetting`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setAmountToCharge(response.data.deductAiCreditSettingData[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const checkRequestBodyValue = (requestBody) => {
    let valid = true;

    if (requestBody.amountToCharge < 0) {
      setAiCreditError('Value must be more than 0');
      valid = false;
    } else {
      setAiCreditError(''); // Clear the error message
    }
    return valid;
  };

  const handleSubmit = async () => {
    try {
      const requestBody = {
        amountToCharge,
      };

      if (!checkRequestBodyValue(requestBody)) {
        return;
      }

      await axios.post(`${api}/v1/credits/deductAiCreditSetting`, requestBody);
      await getLatestSettingData();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLatestSettingData();
  }, []);

  return (
    <div>
      <Button className="filter-btn" icon={<SettingOutlined />} size="large" onClick={handleSettingButton}>
        Deduct Settings
      </Button>
      <Modal
        title={(
          <strong style={{ fontWeight: 'bold' }}>
            Click - AI Credit Deduct Ratio Setting
          </strong>
        )}
        open={isSettingPopUpVisible}
        onOk={handleSubmit}
        onCancel={() => setIsSettingPopUpVisible(false)}
        style={{
          paddingTop: '10rem',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flex: 1, padding: '0 10px' }}>
            <p>Click (Per Wall AI Button)</p>
            <Input
              disabled
              placeholder="Amount in USD"
              style={{ width: '100%' }}
              value={1}
            />
          </div>
          <div style={{ textAlign: 'center', padding: '0 10px', marginTop: '3rem' }}>
            <p>=</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flex: 1, padding: '0 10px' }}>
            <p>Amount To Charge (USD)</p>
            <Input
              placeholder="Amount in AI Credit"
              style={{ width: '100%', borderColor: aiCreditError ? 'red' : '' }}
              value={amountToCharge.amountToCharge}
              onChange={(e) => {
                setAmountToCharge(e.target.value);
                setAiCreditError(''); // Clear the error message on change
              }}
            />
          </div>
        </div>
        <div>
          <p style={{ color: '#999', pointerEvents: 'none' }}>Current 1 USD To AI Credit Amount: {aiCredit}</p>
          <p style={{ color: '#999', pointerEvents: 'none' }}>Current Deduct AI Credit Amount: {amountToCharge.aiCreditDeductValue}</p>
        </div>
        <div style={{ textAlign: 'start', flex: 1, padding: '0 10px' }}>
          {aiCreditError && <p style={{ color: 'red' }}>{aiCreditError}</p>}
        </div> 
      </Modal>
    </div>
  );
}
