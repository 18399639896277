/* eslint-disable indent */
/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { Form, Input, Select, DatePicker, Button, PageHeader, Row, Layout, Col, Skeleton, Tag, Table, Modal } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import axios from 'axios';
import './AuditLog.css';
import { debounce } from 'lodash';
import { getToken } from '../../utils/AuthService';
 
const api = process.env.REACT_APP_LOCAL_URL;

export default function AiCreditSettingButton() {
  const token = getToken();

  const [aiCreditValue, setAiCreditValue] = useState(null);
  const [isSettingPopUpVisible, setIsSettingPopUpVisible] = useState(false);
  const [aiCreditError, setAiCreditError] = useState('');

  const handleSettingButton = () => {
    setIsSettingPopUpVisible(true);
  };

  const getLatestSettingData = async () => {
    try {
      const response = await axios.get(`${api}/v1/credits/setting`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setAiCreditValue(response.data.settingData[0].aiCreditValue);
    } catch (error) {
      console.error(error);
    }
  };

  const checkRequestBodyValue = (requestBody) => {
    let valid = true;

    if (!Number.isInteger(requestBody.aiCreditValue) || requestBody.aiCreditValue <= 0) {
      setAiCreditError('Value must be integer and more than 0');
      valid = false;
    } else {
      setAiCreditError(''); // Clear the error message
    }
    return valid;
  };

  const handleSubmit = debounce(async () => {
    try {
      const requestBody = {
        aiCreditValue: parseInt(aiCreditValue, 10),
      };

      if (!checkRequestBodyValue(requestBody)) {
        return;
      }

      const [settingResponse, deductResponse] = await Promise.all([
        axios.post(`${api}/v1/credits/setting`, requestBody),
        axios.get(`${api}/v1/credits/deductAiCreditSetting`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);
      // eslint-disable-next-line max-len
      const amountToCharge = parseFloat(deductResponse.data.deductAiCreditSettingData[0].amountToCharge);

      if (typeof amountToCharge === 'number') {
        await axios.post(`${api}/v1/credits/deductAiCreditSetting`, { amountToCharge })
          .then(() => {
            console.log('successfully updated');
          })
          .catch((error) => {
            console.error('error:', error);
          });
      }

      window.location.reload();
      setIsSettingPopUpVisible(false);
    } catch (error) {
      console.error(error);
    }
  }, 1500);

  useEffect(() => {
    getLatestSettingData();
  }, []);

  return (
    <div>
      <Button className="filter-btn" icon={<SettingOutlined />} size="large" onClick={handleSettingButton}>
        Settings
      </Button>
      <Modal
        title={(
          <strong style={{ fontWeight: 'bold' }}>
            USD - AI Credit Ratio Setting
          </strong>
        )}
        open={isSettingPopUpVisible}
        onOk={handleSubmit}
        onCancel={() => setIsSettingPopUpVisible(false)}
        style={{
          paddingTop: '10rem',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flex: 1, padding: '0 10px' }}>
            <p>USD</p>
            <Input
              disabled
              placeholder="Amount in USD"
              style={{ width: '100%' }}
              value={1}
            />
          </div>
          <div style={{ textAlign: 'center', padding: '0 10px', marginTop: '3rem' }}>
            <p>=</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flex: 1, padding: '0 10px' }}>
            <p>AI Credit</p>
            <Input
              placeholder="Amount in AI Credit"
              style={{ width: '100%', borderColor: aiCreditError ? 'red' : '' }}
              value={aiCreditValue}
              onChange={(e) => {
                setAiCreditValue(e.target.value);
                setAiCreditError(''); // Clear the error message on change
              }}
            />
          </div>
        </div>
        <div style={{ textAlign: 'start', flex: 1, padding: '0 10px' }}>
          {aiCreditError && <p style={{ color: 'red' }}>{aiCreditError}</p>}
        </div>
      </Modal>
    </div>
  );
}
