/* eslint-disable no-use-before-define */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useCallback } from 'react';
import {
  PageHeader,
  Breadcrumb,
  Button,
  Input,
  Row,
  Modal,
  Form,
  message,
  Skeleton,
  Table,
  Select,
  Card,
  Col,
  Layout,
  Tooltip,
  Tag,
  Typography,
  DatePicker,
} from 'antd';
import { PlusCircleOutlined, InfoCircleOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';

import './pslManagement.css';
import { utils, writeFile } from 'xlsx';
import CountriesList from '../../assets/file/countriesList.json';
import { getToken } from '../../utils/AuthService';
import avSample from '../../assets/file/avSample.pdf';

const { Item } = Form;
const { Option } = Select;
const { Content } = Layout;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const api = process.env.REACT_APP_LOCAL_URL;

export default function PslManagement() {
  const token = getToken();
  const [searchForm] = Form.useForm();
  const [openAddPsl, setOpenAddPsl] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [planData, setPlanData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [userData, setUserData] = useState([]);
  const [userPlans, setUserPlans] = useState([]);
  const [userSubs, setUserSubs] = useState([]);
  const [data, setData] = useState();
  const [searchUsername, setSearchUsername] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchCurrentPlan, setSearchCurrentPlan] = useState('');
  const [searchPlanTimeout, setSearchPlanTimeout] = useState('');
  const [userListData, setUserListData] = useState([]);
  const [totalFilteredResults, setTotalFilteredResults] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '50'],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
  });

  // useEffect to fetch plan title
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${api}/v1/plans`);
        const plans = response.data.pricePlan;
        const activePlans = plans.filter((plan) => plan.isActive === true);
        setPlanData(activePlans);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    // Check if all necessary data is available (userData, userPlans, and userSubs)
    if (isLoading === false) {
      // Call rerenderList with the appropriate data
      rerenderList(userData);
    }
  }, [isLoading]);

  useEffect(async () => {
    setIsLoading(true);
    fetchUserData();
  }, [searchUsername, searchEmail, searchCurrentPlan, searchPlanTimeout, JSON.stringify(tableParams)]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${api}/v1/users`, {
        params: {
          page: tableParams.pagination?.current,
          limit: tableParams.pagination?.pageSize,
          sortBy: 'createdAt',
          userName: searchUsername,
          email: searchEmail,
          currentPlan: searchCurrentPlan,
          planTimeout: searchPlanTimeout,
        },
      });
      const { users } = response.data;
      console.log(users.length);
      const userDatas = users.map((user) => ({
        key: user._id,
        id: user._id,
        userName: user.userName,
        email: user.email,
        userSignUpDate: user.createdAt,
        currentPlan: user.userSubs === null || user.userSubs.archive !== null || user.userSubs.status !== 'active' ? 'No Plan Subscribed' : user.plans.title,
        planTimeout: user.userSubs === null || user.userSubs.archive !== null || user.userSubs.status !== 'active' ? 'Expired' : calculateTimeout(user.userSubs.startDate, user.userSubs.endDate),
      }));
      setUserData(userDatas);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalResults,
        },
      });

      setIsLoading(false);
    } catch (error) {
      message.error('Error:', error);
    }
  };
  
  const exportToCSV = async () => {
    // Make the request to the `exportToCSV` endpoint
    const response = await axios.get(`${api}/v1/users/export`, {
      params: {
        page: tableParams.pagination?.current,
        limit: tableParams.pagination?.pageSize,
        sortBy: 'createdAt',
        userName: searchUsername,
        email: searchEmail,
        currentPlan: searchCurrentPlan,
        planTimeout: searchPlanTimeout,
      },
    });

    // Save the CSV file to the client's computer
    const filename = 'users.csv';
    const blob = new Blob([response.data], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
    downloadLink.setAttribute('download', filename);
    downloadLink.click();
  };

  const exportSelectedUserToCSV = async () => {
    // Make the request to the `exportToCSV` endpoint
    const requestData = {
      userId: selectedRowKeys,
    };

    const response = await axios.post(`${api}/v1/users/selected-export`, requestData, {
      params: {
        page: tableParams.pagination?.current,
        limit: tableParams.pagination?.pageSize,
        sortBy: 'createdAt',
        userName: searchUsername,
        email: searchEmail,
        currentPlan: searchCurrentPlan,
        planTimeout: searchPlanTimeout,
      },
    });

    // Save the CSV file to the client's computer
    const filename = 'users.csv';
    const blob = new Blob([response.data], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
    downloadLink.setAttribute('download', filename);
    downloadLink.click();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  // Calculate userSub timeout
  const calculateTimeout = (startDate, endDate) => {
    const currentDate = new Date();
    const parsedStartDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);

    if (currentDate > parsedEndDate) {
      return 'Expired';
    } else if (parsedStartDate > currentDate) {
      return 'Not started yet';
    } else {
      const timeDiff = parsedEndDate.getTime() - currentDate.getTime();
      const durationInDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      if (durationInDays >= 30) {
        const durationInMonths = Math.floor(durationInDays / 30);
        return `${durationInMonths} Month${durationInMonths > 1 ? 's' : ''}`;
      } else if (durationInDays > 10) {
        return `${durationInDays} days left`;
      } else {
        return 'Less than 10 days left';
      }
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination: {
        ...tableParams.pagination,
        ...pagination,
      },
      filters: {
        ...tableParams.filters,
        ...filters,
      },
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const submitForm = async () => {
    // Fetch Users Data from backend
    const values = await searchForm.validateFields();
    setSearchUsername(values.userManagement.username);
    setSearchEmail(values.userManagement.email);
    setSearchCurrentPlan(values.userManagement.currentPlan);
    setSearchPlanTimeout(values.userManagement.planTimeout);

    // Reset current page to 1
    setTableParams((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current: 1,
      },
    }));

    // // Fetch Users Data from backend
    // fetchData();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitForm();
    }
  };

  const deleteUser = (id) => {
    setSelectedId(id);
    setModalVisible(true);
  };

  const handleDelete = () => {
    axios
      .delete(`${api}/v1/users/${selectedId}`)
      .then(() => {
        message.success('User Successfully Deleted');
        fetchUserData();
      })
      .catch((error) => {
        console.log(error);
        message.error(`${error}`);
      })
      .finally(() => {
        setModalVisible(false);
        setSelectedId(null);
      });
    setModalVisible(false);
    setSelectedId(null);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setSelectedId(null);
  };

  const rerenderList = (user) => {
    const list = [];
    for (let i = 0; i < user.length; i++) {
      list.push({
        key: user[i].id,
        userName: user[i].userName,
        email: user[i].email,
        signUpDate: user[i].signUpDate,
      });
    }
    setUserListData(list);
  };

  const handleExport = useCallback(() => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');

    let selectedRows = [];
    selectedRows = userListData.map(({ key, ...rest }) => rest);

    const renamedColumns = {
      key: 'User Id',
      userName: 'Username',
      email: 'Email',
      signUpDate: 'User Signup Date',
      currentPlan: 'Current Plan',
      planTimeout: 'Plan Timeout',
    };

    const transformedRows = selectedRows.map((row) => Object.entries(row).reduce((acc, [key, value]) => {
      const renamedKey = renamedColumns[key] || key;
      if (key === 'signUpDate') {
        const signUpDate = new Date(value);
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZone: 'Asia/Kuala_Lumpur',
        };
        return { ...acc, [renamedKey]: signUpDate.toLocaleString('en-US', options) };
      }
      return { ...acc, [renamedKey]: value };
    }, {}));

    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');

    // Use writeFile instead of writeFileXLSX
    writeFile(wb, `UserManagement_${formattedDate}.xlsx`);
  }, [userListData]);

  const SearchBar = () => (
    <Form layout="horizontal" form={searchForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 20 }}>
        <Col span={6}>
          <div className="header-section-search-bar-section">Username</div>
          <Item name={['userManagement', 'username']} initialValue={searchUsername}>
            <Input initialValue={searchUsername} placeholder="Enter Username" onKeyDown={handleKeyDown} />
          </Item>
        </Col>
        <Col span={6}>
          <div className="header-section-search-bar-section">Email</div>
          <Item name={['userManagement', 'email']} initialValue={searchEmail}>
            <Input initialValue={searchEmail} placeholder="Enter Email" onKeyDown={handleKeyDown} />
          </Item>
        </Col>
        <Col span={6}>
          <div className="header-section-search-bar-section">Current Plan</div>
          <Item name={['userManagement', 'currentPlan']} initialValue={searchCurrentPlan}>
            <Select
              allowClear
              showSearch
              defaultValue="All"
              style={{ width: '100%' }}
              value={searchCurrentPlan}
              onChange={(value) => setSearchCurrentPlan(value)}
              onKeyDown={handleKeyDown}
            >
              {planData.map((plan) => (
                <Option key={plan._id} value={plan._id}>
                  {plan.title}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={6}>
          <div className="header-section-search-bar-section">Plan Timeout</div>
          <Item name={['userManagement', 'planTimeout']} initialValue={searchPlanTimeout}>
            <Select
              allowClear
              showSearch
              defaultValue="All"
              style={{ width: '100%' }}
              initialValue={searchPlanTimeout}
              onChange={(value) => setSearchPlanTimeout(value)}
              onKeyDown={handleKeyDown}
            >
              <Option value="-1">Expired</Option>
              <Option value="0">Less than 30 Days</Option>
              <Option value="1">1 Month</Option>
              <Option value="3">3 Months</Option>
              <Option value="6">6 Months</Option>
              <Option value="9">9 Months</Option>
              <Option value="12">12 Months</Option>
            </Select>
          </Item>
        </Col>
      </Row>
    </Form>
  );

  // Delete user modal
  const DeleteUserModal = () => (
    <>
      <Modal
        // title="Delete User Modal"
        open={modalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="Cancel"
      >
        <div style={{ textAlign: 'center' }}>
          <InfoCircleOutlined style={{ fontSize: '32px' }} />
          <h1>Are you sure you want to delete?</h1>
          <p>If you confirm this action, you are unable to undo.</p>
        </div>
      </Modal>
    </>
  );

  const colorMap = {
    'Less than 10 days left': 'green',
    Expired: 'red',
  };

  const generalAvColumns = [
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'User Signup Date',
      dataIndex: 'userSignUpDate',
      key: 'userSignUpDate',
      align: 'center',
      render: (userSignUpDate) => new Date(userSignUpDate).toLocaleString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
    },
    {
      title: 'Current Plan',
      dataIndex: 'currentPlan',
      key: 'currentPlan',
      align: 'center',
      render: (currentPlan) => <span>{currentPlan || 'No Plan Subscribed'}</span>
      // const userPlan = userPlans.find((plan) => plan.userId === userId);
      // return <span>{userPlan ? userPlan.title : 'No Plan Subscribed'}</span>;
        
      ,
    },
    {
      title: 'Plan Timeout',
      dataIndex: 'planTimeout',
      key: 'planTimeout',
      align: 'center',
      render: (planTimeout) => {
        // const userSub = userSubs.find((sub) => sub.key === id);
        // const timeout = userSub ? userSub.timeout : 'NA';
        const color = colorMap[planTimeout] || 'green';
        return (
          <Tag style={{ fontWeight: 500 }} color={color} key={planTimeout}>
            {planTimeout}
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      render: (_, item) => {
        const { id, status } = item;
        return (
          <div className="general-av-action-col">
            <DeleteOutlined
              style={{ color: 'red', fontSize: '18px' }}
              onClick={() => deleteUser(id)}
            />
          </div>
        );
      },
    },
  ];

  // PSL list table component
  const TablePslManagement = () => (
    <div className="table-content">
      {isLoading ? (
        <Skeleton style={{ marginInline: '20px' }} />
      ) : (
        <Table
          scroll={{ x: 1200 }}
          columns={generalAvColumns}
          dataSource={userData}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
          style={{ paddingInline: '20px' }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}

        />
      )}
    </div>
  );

  return (
    <>
      <Content className="layout-background">
        <div className="breadcrumb-layout">
          <Row style={{ justifyContent: 'space-between' }}>
            <PageHeader title="User Management" className="basic-pageheader" />
            <Col style={{ display: 'flex', alignItems: 'center', marginRight: '20px', gap: '5px' }}>
              <Button
                className="filter-btn"
                icon={<UploadOutlined />}
                size="large"
                onClick={selectedRowKeys.length !== 0 ? exportSelectedUserToCSV : exportToCSV}
              >
                Export
              </Button>
            </Col>
          </Row>
        </div>
        <div style={{ padding: 20 }}>
          <SearchBar />
        </div>
        <TablePslManagement />
        <DeleteUserModal />
      </Content>
    </>
  );
}
