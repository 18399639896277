/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { FilterTwoTone } from '@ant-design/icons';
import { Row, Col, Table, Form, Select, Input, Typography, Modal, Button, DatePicker } from 'antd';
import { Column } from '@ant-design/charts';

import TopMostStatistics from '../../components/dashboard/TopMostStatistics';

const { Option } = Select;
const { TextArea } = Input;
const { Item } = Form;
const { Title } = Typography;
const api = process.env.REACT_APP_LOCAL_URL;

export default function Dashboard() {
  const [startDates, setStartDates] = useState();
  const [endDates, setEndDates] = useState();
  const [searchForm] = Form.useForm();
  const [planData, setPlanData] = useState([]);
  const [searchPlan, setSearchPlan] = useState('');
  const [planChart, setPlanChart] = useState('');

  const nameColors = {
    'Standard Plan': '#FF4D4F', // Red color
    'Annual Plan': '#597EF7', // Blue color
  };

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${api}/v1/plans`);
        const plans = response.data.pricePlan;

        // Filter the plans array to include only active status
        const activePlans = plans.filter((plan) => plan.isActive === true);
        setPlanData(activePlans);
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    const fetchPlansChart = async () => {
      try {
        const response = await axios.get(`${api}/v1/plans/charts`, { 
          params: {
            planID: searchPlan,
          }, 
        });
        const plans = response.data.chartData;
        console.log('plans', plans);
        const monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec',
        ];

        // Initialize an empty array to store the transformed data
        const transformedData = [];

        // Your existing forEach loop
        plans.forEach((plan) => {
          plan.data.forEach((month) => {
            // eslint-disable-next-line radix
            const monthName = monthNames[parseInt(month.month) - 1];

            const transformedMonth = {
              name: plan.title,
              month: monthName,
              count: month.count,
            };

            transformedData.push(transformedMonth);
          });
        });

        console.log(transformedData);
        setPlanChart(transformedData);
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlans();
    fetchPlansChart();
  }, [searchPlan]);

  const BarGraphManagement = () => {
    const data = [
      {
        name: 'Plan 1',
        month: 'Jan.',
        avg_monthly_rainfall: 200,
      },
      {
        name: 'Plan 1',
        month: 'Feb.',
        avg_monthly_rainfall: 300,
      },
      {
        name: 'Plan 1',
        month: 'Mar.',
        avg_monthly_rainfall: 289,
      },
      {
        name: 'Plan 1',
        month: 'Apr.',
        avg_monthly_rainfall: 349,
      },
      {
        name: 'Plan 1',
        month: 'May.',
        avg_monthly_rainfall: 379,
      },
      {
        name: 'Plan 1',
        month: 'Jun.',
        avg_monthly_rainfall: 259,
      },
      {
        name: 'Plan 1',
        month: 'Jul.',
        avg_monthly_rainfall: 129,
      },
      {
        name: 'Plan 1',
        month: 'Aug.',
        avg_monthly_rainfall: 169,
      },
      {
        name: 'Plan 2',
        month: 'Jan.',
        avg_monthly_rainfall: 159,
      },
      {
        name: 'Plan 2',
        month: 'Feb.',
        avg_monthly_rainfall: 249,
      },
      {
        name: 'Plan 2',
        month: 'Mar.',
        avg_monthly_rainfall: 219,
      },
      {
        name: 'Plan 2',
        month: 'Apr.',
        avg_monthly_rainfall: 339,
      },
      {
        name: 'Plan 2',
        month: 'May.',
        avg_monthly_rainfall: 389,
      },
      {
        name: 'Plan 2',
        month: 'Jun.',
        avg_monthly_rainfall: 249,
      },
      {
        name: 'Plan 2',
        month: 'Jul.',
        avg_monthly_rainfall: 149,
      },
      {
        name: 'Plan 2',
        month: 'Aug.',
        avg_monthly_rainfall: 109,
      },
      {
        name: 'Plan 3',
        month: 'Jan.',
        avg_monthly_rainfall: 189,
      },
      {
        name: 'Plan 3',
        month: 'Feb.',
        avg_monthly_rainfall: 200,
      },
      {
        name: 'Plan 3',
        month: 'Mar.',
        avg_monthly_rainfall: 80,
      },
      {
        name: 'Plan 3',
        month: 'Apr.',
        avg_monthly_rainfall: 100,
      },
      {
        name: 'Plan 3',
        month: 'May.',
        avg_monthly_rainfall: 290,
      },
      {
        name: 'Plan 3',
        month: 'Jun.',
        avg_monthly_rainfall: 219,
      },
      {
        name: 'Plan 3',
        month: 'Jul.',
        avg_monthly_rainfall: 156,
      },
      {
        name: 'Plan 3',
        month: 'Aug.',
        avg_monthly_rainfall: 400,
      },
    ];
    const config = {
      data: planChart, // Assuming 'planChart' is your data variable containing the JSON data you provided
      isGroup: true,
      xField: 'month', // Map to the 'month' property in your data
      yField: 'count', // Map to the 'count' property in your data
      seriesField: 'name', // Map to the 'name' property in your data (changed from 'title')
      // color: ['#597EF7', '#FF4D4F', '#FFA940'],
      color: ({ name }) => nameColors[name],
    };
    return (
      <div className="table-content" style={{ padding: 20, backgroundColor: 'white', borderRadius: 8 }}>
        <Column {...config} />
      </div>
    );
  };

  return (
    <div className="dashboard-content-layout">
      <div className="dashboard-title-on-filter">
        <Title level={4}>Overview</Title>
        {/* <SearchBar /> */}
      </div>
      <TopMostStatistics startDate={startDates} endDate={endDates} />
      <Col style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Title level={4}>Pricing Plans Purchased</Title>
        <Select
          allowClear
          // showSearch
          defaultValue="All"
          style={{ width: '20%' }}
          value={searchPlan || 'All'}
          onChange={(value) => setSearchPlan(value)}
        // onKeyDown={handleKeyDown}
        >
          {planData.map((plan) => (
            <Option key={plan._id} value={plan._id}>
              {plan.title}
            </Option>
          ))}
        </Select>
      </Col>
      {/* <AvFilterModal /> */}
      <BarGraphManagement />
    </div>
  );
}
