/* eslint-disable no-console */
import React, { useState } from 'react';
import { BrowserRouter as Router, NavLink, useHistory } from 'react-router-dom';

// Ant Design
import { Layout, Menu, Row, Col, Button, message, Typography, Tooltip, Dropdown, Space } from 'antd';
import {
  DashboardOutlined,
  UserOutlined,
  TableOutlined,
  FormOutlined,
  AuditOutlined,
  LogoutOutlined,
  FileOutlined,
  DownOutlined,
  BarChartOutlined,
  DollarOutlined,
  AccountBookOutlined,
  EuroOutlined,
} from '@ant-design/icons';

import axios from 'axios';
import Cookie from 'js-cookie';

// File
import './layout.css';
import '../App.less';
import '../styles/general.css';
import logo from '../assets/images/logofire.svg';
import { getToken, removeUserSession, getUser } from '../utils/AuthService';
import RouteList from '../routes/RouteLists';

const { Header, Content, Sider } = Layout;
const { Link, Title } = Typography;

export default function LayoutPage() {
  const history = useHistory();
  const token = getToken();
  const user = getUser();
  const api = process.env.REACT_APP_LOCAL_URL;

  let activeHref;
  const { location } = window;
  const hasQueryParam = window.location.href.includes('?');

  if (hasQueryParam === true) {
    activeHref = location.pathname.split('/', 2)[1];
  } else {
    activeHref = location.href.split('/')[3];
    // console.log('false: ', activeHref);
  }

  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    console.log('token is', token);
    axios
      .post(`${api}/v1/auth/logout`, {
        refreshToken: token.refresh.token,
      })
      .then(() => {
        removeUserSession();
        Cookie.remove('selectedTab');
        history.push('/login');
        window.location.reload();
      })
      .catch((e) => {
        message.error(`${e}. Please try again.`);
      });
  };

  //* general menu items
  const menuItems = [
    {
      key: '/',
      label: (
        <NavLink to="/" activeclassname="active">
          {/* Dashboard */}
          Dashboard
        </NavLink>
      ),
      icon: <BarChartOutlined />,
    },
    {
      key: '/user-management',
      label: (
        <NavLink to="/user-management" activeclassname="active">
          {/* PSL Management */}
          User Management
        </NavLink>
      ),
      icon: <UserOutlined />,
    },
    {
      key: '/pricing-plans',
      label: (
        <NavLink to="/pricing-plans" activeclassname="active">
          {/* Quotation History */}
          Pricing Plans
        </NavLink>
      ),
      icon: <AuditOutlined />,
    },
    {
      key: '/user-transaction',
      label: (
        <NavLink to="/user-transaction" activeclassname="active">
          Transaction
        </NavLink>
      ),
      icon: <AccountBookOutlined />,
    },
    {
      key: '/ai-credit',
      label: (
        <NavLink to="/ai-credit" activeclassname="active">
          AI Credit
        </NavLink>
      ),
      icon: <EuroOutlined />,
    },
  ];

  //* administrator only menu items
  const administratorMenuItems = [
    {
      key: '/',
      label: (
        <NavLink to="/" activeclassname="active">
          Home
        </NavLink>
      ),
      icon: <DashboardOutlined />,
    },
    {
      key: '/psl-management',
      label: (
        <NavLink to="/psl-management" activeclassname="active">
          {/* PSL Management */}
          General AV
        </NavLink>
      ),
      icon: <AuditOutlined />,
    },
    {
      key: '/quotation-history',
      label: (
        <NavLink to="/quotation-history" activeclassname="active">
          {/* Quotation History */}
          Condolences AV
        </NavLink>
      ),
      icon: <FileOutlined />,
    },
    {
      key: '/run-payment',
      label: (
        <NavLink to="/run-payment" activeclassname="active">
          {/* RFP */}
          Audit Trail
        </NavLink>
      ),
      icon: <AuditOutlined />,
    },
    {
      key: 'consultant',
      label: 'User Permission',
      icon: <UserOutlined />,
      children: [
        {
          label: (
            <NavLink to="/consultant-management" activeclassname="active">
              User Lists
            </NavLink>
          ),
          key: '/consultant-management',
        },
        {
          label: (
            <NavLink to="/call-out-management" activeclassname="active">
              Roles Permission
            </NavLink>
          ),
          key: '/call-out-management',
        },
      ],
    },
    {
      key: 'invoice',
      label: 'RFI',
      icon: <TableOutlined />,
      children: [
        {
          label: (
            <NavLink to="/invoice-list" activeclassname="active">
              Invoice List
            </NavLink>
          ),
          key: '/invoice-list',
        },
        {
          label: (
            <NavLink to="/invoice-history" activeclassname="active">
              Invoice History
            </NavLink>
          ),
          key: '/invoice-history',
        },
      ],
    },
    {
      key: '/audit-log',
      label: (
        <NavLink to="/audit-log" activeclassname="active">
          Audit Log
        </NavLink>
      ),
      icon: <FormOutlined />,
    },
    {
      key: '/',
      label: (
        <NavLink to="/" activeclassname="active">
          {/* Dashboard */}
          Dashboard
        </NavLink>
      ),
      icon: <BarChartOutlined />,
    },
    {
      key: '/psl-management',
      label: (
        <NavLink to="/psl-management" activeclassname="active">
          {/* PSL Management */}
          User Management
        </NavLink>
      ),
      icon: <UserOutlined />,
    },
    {
      key: '/quotation-history',
      label: (
        <NavLink to="/quotation-history" activeclassname="active">
          {/* Quotation History */}
          Pricing Plans
        </NavLink>
      ),
      icon: <AuditOutlined />,
    },
    {
      key: '/audit-log',
      label: (
        <NavLink to="/audit-log" activeclassname="active">
          Transaction
        </NavLink>
      ),
      icon: <DollarOutlined />,
    },
  ];

  const items = [
    {
      key: '1',
      label: (
        <Button type="link">
          <UserOutlined />
          Admin
        </Button>
      ),
    },
    {
      key: '2',
      label: (
        <Button type="link" onClick={handleLogout}>
          <LogoutOutlined />
          Logout
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Router>
        <Header className="header-layout">
          <Row>
            <Col span={8}>
              <Link style={{ color: '#2F3F54' }} href="/">
                <img src={logo} alt="logo" width={100} />
                <p>Home</p>
              </Link>
            </Col>
            <Col span={8} offset={8}>
              <Row justify="end" align="middle">
                {/* <Col span={2}>
                  <Title level={5} style={{ margin: '0px' }}>CN</Title>
                </Col> */}
                <Col span={4}>
                  {/* <Dropdown
                    menu={{
                      items,
                      selectable: true,
                    }}>
                    <Typography.Link>
                      <Space>
                        Admin
                        <DownOutlined />
                      </Space>
                    </Typography.Link>
                  </Dropdown> */}
                  <Dropdown menu={{ items }}>
                    <Space>
                      Admin
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            width={250}
            className="menu-layout"
            collapsible
            collapsed={collapsed}
            onCollapse={handleCollapse}
          >
            <Menu
              defaultOpenKeys={['consultant', 'invoice']}
              defaultSelectedKeys={[`/${activeHref}`]}
              mode="inline"
              onClick={() => {
                Cookie.remove('selectedTab');
              }}
              items={user.role === 'administrator' ? administratorMenuItems : menuItems}
            />
          </Sider>
          <Layout>
            <Content className="content-layout">{RouteList()}</Content>
          </Layout>
        </Layout>
      </Router>
    </div>
  );
}
