/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Table,
  Col,
  PageHeader,
  Layout,
  DatePicker,
  Select,
  Row,
  Card,
  Button,
  Skeleton,
  message,
  Tooltip,
  Form,
  Tag,
  Input,
} from 'antd';

import axios from 'axios';
import moment from 'moment';

import './AuditLog.css';
import { getToken } from '../../utils/AuthService';
import { capitalizedFirstAlphabet } from '../../utils/capitalizedFirstAlphabet';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Item } = Form;
const api = process.env.REACT_APP_LOCAL_URL;

export default function AuditLog() {
  const token = getToken();
  const [searchForm] = Form.useForm();

  const [searchTransactionID, setSearchTransactionID] = useState('');
  const [searchUsername, setSearchUsername] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [data, setData] = useState();
  const [startDates, setStartDates] = useState();
  const [endDates, setEndDates] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '50'],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
  });

  useEffect(() => {
    console.log(searchUsername);
    console.log(searchTransactionID);
    console.log(searchStatus);
    console.log(startDates);
    console.log(endDates);
    fetchPlan();
    setLoadingTransaction(false);
  }, [searchUsername, searchTransactionID, searchStatus, startDates, endDates]);

  useEffect(() => {
    fetchPlan();
    setLoadingTransaction(false);
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination: {
        ...tableParams.pagination,
        ...pagination,
      },
      filters: {
        ...tableParams.filters,
        ...filters,
      },
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleDateChange = (value) => {
    if (value && value.length === 2) {
      // Assuming that the selected date range is an array with two elements: [startDate, endDate]
      const [startDate, endDate] = value;
      setStartDates(startDate.toISOString());
      setEndDates(endDate.toISOString());
      console.log('Selected date range:', startDate.toISOString(), endDate.toISOString());
      // fetchPlan();
      setLoadingTransaction(false);
    }
  };

  const fetchPlan = async () => {
    setLoadingTransaction(true);
    await axios
      .get(`${api}/v1/usertransactions`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
        params: {
          page: tableParams.pagination?.current,
          limit: tableParams.pagination?.pageSize,
          sortBy: 'desc',
          userName: searchUsername,
          transactionID: searchTransactionID,
          status: searchStatus,
          startDate: startDates,
          endDate: endDates,
        },
      })
      .then((results) => {
        setTransactionData(results.data.transactions);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: results.data.totalResults,
          },
        });
        return results;
      })
      .catch((error) => {
        message.error(`Failed to retrieve Plan data. ${error}`);
      });
  };

  const searchData = async () => {
    const values = await searchForm.validateFields();
    console.log(values.searchTransaction);
    setSearchUsername(values.searchTransaction.userName);
    setSearchTransactionID(values.searchTransaction.transactionID);
    setSearchStatus(values.searchTransaction.status);
    // console.log(searchPublishDate._d);
    setTableParams((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current: 1,
      },
    }));

    // fetchPlan();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      searchData();
    }
  };

  const generalAvColumns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transactionID',
      key: 'transactionID',
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'username',
      align: 'center',
    },
    {
      title: 'Plan Subscribed',
      dataIndex: 'planSubs',
      key: 'planSubs',
      align: 'center',
    },
    {
      title: 'Price',
      dataIndex: 'transactionPrice',
      key: 'price',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => {
        const colorMap = {
          success: 'green',
          pending: 'blue',
          failed: 'red',
          cancelled: 'grey',
        };

        const color = colorMap[status] || 'defaultColor';

        return (
          <Tag style={{ fontWeight: 500 }} color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'payment',
      align: 'center',
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      align: 'center',
      render: (invoiceDate) => new Date(invoiceDate).toLocaleString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }),
    },
  ];

  const SearchBar = () => (
    <Form layout="horizontal" form={searchForm}>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 20,
        }}>
        <Col span={6}>
          <div className="header-section-search-bar-section">
            Transaction ID
          </div>
          <Item name={['searchTransaction', 'transactionID']} initialvalue={searchTransactionID}>
            <Input initialvalues={searchTransactionID} placeholder="Enter Transaction ID" onKeyDown={handleKeyDown} allowClear />
          </Item>
        </Col>
        <Col span={6}>
          <div className="header-section-search-bar-section">
            Username
          </div>
          <Item name={['searchTransaction', 'userName']} initialvalue={searchUsername}>
            <Input initialvalues={searchUsername} placeholder="Enter Username" onKeyDown={handleKeyDown} />
          </Item>
        </Col>
        <Col span={6}>
          <Item name={['searchTransaction', 'status']} initialvalue={searchStatus}>
            <div className="header-section-search-bar-section">
              Status
              <Select
                allowClear
                showSearch
                defaultValue="All"
                style={{ width: '100%' }}
                initialValue={searchStatus}
                onChange={(value) => setSearchStatus(value)}
              >
                <Option value="success">Success</Option>
                <Option value="failed">Failed</Option>
                <Option value="pending">Pending</Option>
                <Option value="cancelled">Cancelled</Option>
              </Select>
            </div>
          </Item>
        </Col>
        <Col span={6}>
          <div>
            Invoice Date
          </div>
          <Item name={['searchTransaction', 'publishDate']}>
            <RangePicker
              // initialValue={searchPublishDate} 
              onChange={handleDateChange}
              style={{ width: '100%' }}
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );

  const AuditLogTable = () => (
    <div className="table-content">
      {loadingTransaction ? (
        <Skeleton />
      ) : (
        <Table
          // scroll={{ x: 1100 }}
          // columns={columns}
          // dataSource={auditLogData}
          scroll={{ x: 1500 }}
          columns={generalAvColumns}
          dataSource={transactionData}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
          style={{ marginInline: '24px' }}
        />
      )}
    </div>
  );

  const BreadCrumbDiv = () => (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/audit-log">Transaction</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </>
  );

  const PageHeaderDiv = () => <PageHeader title="Transaction" className="basic-pageheader" />;

  return (
    <>
      <div className="breadcrumb-layout">
        {/* <BreadCrumbDiv /> */}
        <PageHeaderDiv />
      </div>

      <Content className="layout-background">
        <div style={{ padding: 20 }}>
          <SearchBar />
        </div>
        <AuditLogTable />
      </Content>
    </>
  );
}
